import React, { createContext, useState, useEffect, useReducer } from "react";
import FamilyUserReducer from "./FamilyUserReducer";
import http from "../Services/Interceptor";

const initialState = {
	loading: false,
	familyUser: JSON.parse(localStorage.getItem("familyUser")) || {},
	isLogged: JSON.parse(sessionStorage.getItem("isLogged")) || false,
	seniorSelectedId: JSON.parse(localStorage.getItem("seniorSelectedId")) || null,
	seniorHomeId: JSON.parse(localStorage.getItem("seniorHomeId")) || null,
	locale: JSON.parse(localStorage.getItem("locale")) || "en"
};

export const FamilyUserContext = createContext();

export const FamilyUserProvider = ({ children }) => {
	const [seniorHome, setSeniorHome] = useState([]);
	const [userState, userDispatch] = useReducer(FamilyUserReducer, initialState);
	const urlHomes = "/api/family/v1/homes";

	useEffect(() => {
		let isMounted = true;
		const handleHome = homeId => {
			http
				.get(`${urlHomes}/${homeId}`)
				.then(res => setSeniorHome(res.data.home))
				.catch(() => setSeniorHome([]));
		};
		if (userState.isLogged && userState.seniorHomeId && isMounted) {
			handleHome(userState.seniorHomeId);
		}
		return () => (isMounted = false);
		// eslint-disable-next-line
	}, [userState.isLogged, userState.seniorHomeId]);

	return (
		<FamilyUserContext.Provider
			value={{
				seniorHome,
				userState,
				userDispatch
			}}
		>
			{children}
		</FamilyUserContext.Provider>
	);
};

export const FamilyUserConsumer = FamilyUserContext.Consumer;
