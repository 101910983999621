import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../Profile.scss";
import { Link } from "react-router-dom";
import http from "../../../Services/Interceptor";
import * as url from "../../../Constants/Url";

interface AddLifestoryProps {
	setAddMode: Function;
	seniorSelectedId: number;
	esDispatch: Function;
}

const AddLifestory = ({ setAddMode, seniorSelectedId, esDispatch }: AddLifestoryProps) => {
	const { t } = useTranslation("common");
	const [lifestory, setLifestory] = useState<string | null>(null);
	const [rgpd, setRgpd] = useState(false);

	const handleLifestory = (e: React.SyntheticEvent) => {
		e.preventDefault();
		esDispatch({
			type: "RESET_ES"
		});
		if (lifestory && rgpd) {
			const data = {
				life_story: lifestory
			};

			http
				.post(`${url.SENIORS}/${seniorSelectedId}/life_stories`, data)
				.then(() => {
					esDispatch({
						type: "DISPLAY_SUCCESS",
						payload: t("link.__addBioSuccess__")
					});
					setAddMode(false);
				})
				.catch(() => {
					esDispatch({
						type: "DISPLAY_ERROR",
						payload: t("link.__addBioError__")
					});
				});
		} else {
			esDispatch({
				type: "DISPLAY_ERROR",
				payload: t("common.__rgpdError__")
			});
		}
	};

	return (
		<form onSubmit={handleLifestory} className="colored-background" style={{ marginTop: "1.5rem" }}>
			<input type="text" onChange={e => setLifestory(e.target.value)} required placeholder={t("link.__addBio__")} />
			<div className="rgpd">
				<p className="inline meta-checkbox">
					<input
						type="checkbox"
						checked={rgpd}
						name="rgpd"
						style={{ marginRight: "0.5rem" }}
						onChange={() => setRgpd(prevRgpd => !prevRgpd)}
					/>
					<Link to={"/rgpd"}>{t("common.__checkRgpd__")}</Link>
				</p>
			</div>
			<button type="submit" className="dark-button">
				{t("common.__save__")}
			</button>
		</form>
	);
};

export default AddLifestory;
