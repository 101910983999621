import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import UserStateInterface from "../../interfaces/UserStateInterface";
import Loader from "../../Layout/Loader";
import NoDataAvailable from "../../Layout/NoDataAvailable";
import SocialNewsContainer from "./SocialNewsContainer";
import HeaderContainer from "../../Layout/HeaderContainer";

interface SocialNewsProps {
	seniorSelectedId: number;
	userState: UserStateInterface;
}

export default function SocialNews({ seniorSelectedId, userState }: SocialNewsProps) {
	const { t } = useTranslation("common");
	// const queryClient = useQueryClient();

	const { status, data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
		["blog_posts", seniorSelectedId],
		async ({ pageParam = 0 }) => {
			const res = await http.get(`${url.SENIORS}/${seniorSelectedId}/blog_posts?nb=2&idx=` + pageParam);
			return res.data;
		},
		{
			getPreviousPageParam: firstPage => firstPage.previousId ?? false,
			getNextPageParam: lastPage => lastPage.nextId ?? false
		}
	);

	const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
	useIntersectionObserver({
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage
	});

	// const addLike = async (id: any) => {
	// 	await mutate(id);
	// };

	// const { mutate } = useMutation(postId => http.get(`${url.BASEURL}/blog_posts/${postId}/like`), {
	// 	onSuccess: () => {
	// 		queryClient.invalidateQueries(["blog_posts", seniorSelectedId]);
	// 	}
	// });

	return (
		<>
			<HeaderContainer title={t("common.__blog__")} />

			{status === "loading" ? (
				<Loader />
			) : status === "error" ? (
				<NoDataAvailable />
			) : (
				<>
					{data ? (
						data.pages[0].data.length > 0 ? (
							<>
								{data.pages.map((group, i) => (
									<div key={i}>
										{group.data.map((post: any) => (
											<SocialNewsContainer key={post.id} userState={userState} seniorSelectedId={seniorSelectedId} post={post} />
										))}
									</div>
								))}
								<div className="center">
									<button
										ref={loadMoreButtonRef}
										onClick={() => fetchNextPage()}
										disabled={!hasNextPage || isFetchingNextPage}
										className="light-button"
									>
										{isFetchingNextPage ? t("common.__loading__") : hasNextPage ? t("common.__more__") : null}
									</button>
									{isFetching && !isFetchingNextPage ? t("common.__loading__") : null}
								</div>
							</>
						) : (
							<>
								<p className="meta">{t("news.__noNews__")}</p>
								<div className="space"></div>
							</>
						)
					) : null}
				</>
			)}
		</>
	);
}
