import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import ErrorContainer from "../../Layout/ErrorContainer";
import { Link } from "react-router-dom";
import MyInputMail from "../../utils/form/MyInputMail";
interface FormValues {
	email: string;
}

interface ForgotPasswordProps {
	displayTitle: boolean;
}

const ForgotPassword = ({ displayTitle }: ForgotPasswordProps) => {
	const { t } = useTranslation("common");
	const methods = useForm<FormValues>();
	const { handleSubmit } = methods;

	const [httpResponse, setHttpResponse] = useState<{ error: boolean } | null>(null);

	const onSubmit = (values: FormValues) => {
		setHttpResponse(null);
		const data = {
			email: values.email
		};
		http
			.post(`${url.BASEURL}/lost_password`, data)
			.then(() => setHttpResponse({ error: false }))
			.catch(() => setHttpResponse({ error: true }));
	};

	return (
		<FormProvider {...methods}>
			<ErrorContainer
				alert={t("user.__lostPasswordError__")}
				success={t("user.__lostPasswordSuccess__")}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>
			<div className="center mb-5 mt-2">
				{displayTitle ? <h2>{t("user.__lostPassword__")}</h2> : null}

				<form onSubmit={handleSubmit(onSubmit)}>
					<MyInputMail label="Saisissez votre email :" name="email" behavior={{ required: true, pattern: /^\S+@\S+\.\S+$/ }} />
					<br />
					<Link to={"/"}>
						<button type="button" className="light-button">
							{t("common.__cancel__")}
						</button>
					</Link>
					<button type="submit" className="dark-button">
						{t("common.__validate__")}
					</button>
				</form>
			</div>
		</FormProvider>
	);
};

export default ForgotPassword;
