import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { useForm, FormProvider } from "react-hook-form";
import { FamilyUserContext } from "../contexts/FamilyUserContext";
import MainLayout from "../Layout/MainLayout";
import Link from "../pages/Link";
import Messages from "../pages/Messages";
import Calendar from "../pages/Calendar";
import Avatar from "../pages/Avatar";
import Social from "../pages/Social";
import PostCards from "../pages/PostCards";
import Timeline from "../pages/Timeline";
import RgpdContainer from "../pages/RgpdContainer";
import UserAccount from "../pages/UserAccount";
import LiveMeeting from "../components/Jitsi/LiveMeeting";
import Flow from "../pages/Flow";
import HomeDetails from "../pages/HomeDetails";
import "./App.scss";

const ProtectedApp = () => {
	const { userState, userDispatch, error } = useContext(FamilyUserContext);
	const queryClient = new QueryClient();
	const methods = useForm();

	return (
		<>
			<MainLayout>
				<FormProvider {...methods}>
					<Route exact path="/livemeeting/:subject/:room/:token" render={() => <LiveMeeting userState={userState} />} />
					{/* Timeline */}
					<Route exact path="/activities" render={() => <Timeline userState={userState} userDispatch={userDispatch} />} />
					<Route exact path="/activities/:filter" render={() => <Timeline userState={userState} userDispatch={userDispatch} />} />

					{/* Routes with infinite Scroll */}
					<QueryClientProvider client={queryClient}>
						{/* Flow */}
						<Route exact path="/flow" render={() => <Flow userState={userState} userDispatch={userDispatch} />} />
						{/* Blog */}
						<Route exact path="/social" render={() => <Social userState={userState} userDispatch={userDispatch} />} />
						<Route exact path="/social/:postId" render={() => <Social userState={userState} userDispatch={userDispatch} />} />
						{/* Link pages */}
						<Route exact path="/profile/:component" render={() => <Link userState={userState} userDispatch={userDispatch} />} />
					</QueryClientProvider>

					{/* Link - Pages without infinite Scroll */}
					<Route
						exact
						path="/profile/:component/:msgId"
						render={() => <Link userState={userState} userDispatch={userDispatch} />}
					/>
					<Route exact path="/postcard" render={() => <PostCards userState={userState} />} />
					<Route exact path="/avatar" render={() => <Avatar userState={userState} userDispatch={userDispatch} />} />

					{/* Info & elements from the home */}
					<Route exact path="/seniorhome" render={() => <HomeDetails userState={userState} userDispatch={userDispatch} />} />
					<Route
						exact
						path="/messages/:component"
						render={() => <Messages userState={userState} userDispatch={userDispatch} />}
					/>
					<Route
						exact
						path="/messages/:component/:mode/:id"
						render={() => <Messages userState={userState} userDispatch={userDispatch} />}
					/>
					<Route exact path="/calendar" render={() => <Calendar userState={userState} userDispatch={userDispatch} />} />

					{/* User Settings */}
					<Route
						exact
						path="/settings/:component"
						render={() => <UserAccount userState={userState} userDispatch={userDispatch} error={error} />}
					/>
					<Route exact path="/rgpd" render={() => <RgpdContainer />} />
				</FormProvider>
			</MainLayout>
		</>
	);
};

export default ProtectedApp;
