import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FamilyUserContext } from "../contexts/FamilyUserContext";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import i18next from "../i18n/i18next";
import Rgpd from "../components/UserSettings/Rgpd";
import ErrorContainer from "../Layout/ErrorContainer";
import Footer from "../Layout/Footer";
import http from "../Services/Interceptor";
import * as url from "../Constants/Url";
import "../App/App.scss";

interface FormValues {
	login: string;
	password: string;
}

const Login = () => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const { userDispatch } = useContext(FamilyUserContext);
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<FormValues>();
	const [httpResponse, setHttpResponse] = useState<{ error: boolean } | null>(null);
	const [rgpdModal, setRgpdModal] = useState<boolean>(false);
	const [rgpdError, setRgpdError] = useState<boolean>(false);
	const [hasAcceptedRgpd, setHasAcceptedRgpd] = useState<boolean>(true);
	const [viewPassword, setViewPassword] = useState<boolean>(false);

	const handleRgpd = () => {
		setHasAcceptedRgpd(prevState => !prevState);
		const data = { has_accepted_rgpd: hasAcceptedRgpd };

		http
			.post(`${url.BASEURL}/me/rgpd/accept`, data)
			.then(() => {
				setRgpdError(false);
				history.push(`/activities/`);
			})
			.catch(() => setRgpdError(true));
	};

	interface ModalInterface {
		rgpdModal: boolean;
		setRgpdModal: Function;
	}

	const Modal = ({ rgpdModal, setRgpdModal }: ModalInterface) => {
		return rgpdModal ? (
			<div className="overlay" onClick={() => setRgpdModal(false)}>
				<div className="modal-rgpd">
					<div className="modal-body">
						<Rgpd />
					</div>
					<div className="rgpd-close-button">
						<h3 className="label-rgpd">
							<input type="checkbox" name="has_accepted_rgpd" onClick={handleRgpd} />
							{t("user.__acceptRgpd__")}
						</h3>
					</div>
					<div className="rgpd-close-button" role="button" onClick={() => setRgpdModal(false)}>
						{t("user.__refuseRgpd__")}
					</div>
				</div>
			</div>
		) : null;
	};
	// LOGIN
	const onSubmit = (data: FormValues) => {
		setHttpResponse(null);
		const loginData = {
			family_user: {
				login: data.login,
				password: data.password
			}
		};
		http
			.post(`${url.BASEURL}/login`, loginData)
			.then(res => {
				userDispatch({
					type: "LOGIN",
					payload: res.data.family_user
				});
				localStorage.setItem("familyUser", JSON.stringify(res.data.family_user));
				sessionStorage.setItem("isLogged", JSON.stringify(true));
				localStorage.setItem("seniorSelectedId", JSON.stringify(res.data.family_user.seniors[0].id));
				localStorage.setItem("seniorHomeId", JSON.stringify(res.data.family_user.seniors[0].home_id));
				localStorage.setItem("locale", JSON.stringify(res.data.family_user.locale));

				if (res.data.family_user.locale === "fr") {
					localStorage.setItem("i18next", "fr-FR");
					i18next.changeLanguage("fr-FR");
				} else if (res.data.family_user.locale === "nl") {
					localStorage.setItem("i18next", "nl-BE");
					i18next.changeLanguage("nl-BE");
				} else {
					localStorage.setItem("i18next", "en-EN");
					i18next.changeLanguage("en-EN");
				}

				if (res.data.family_user.has_accepted_rgpd) {
					history.push("/flow");
				} else {
					setRgpdModal(true);
				}
			})
			.catch(() => {
				userDispatch({
					type: "LOGIN_ERROR"
				});
				setHttpResponse({ error: true });
			});
	};

	return (
		<>
			<div>
				{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
					<img src="/img/Logo-eforlink.png" alt="Logo eforlink" className="home-logo" />
				) : (
					<img src="/img/amd/amdlink.png" alt="Logo AMD link" className="home-logo" />
				)}
				<div id="login-module">
					<h1>{t("home.__slogan__")}</h1>
					<p className="slogan">{t("home.__sloganKeywords__")}</p>
				</div>
			</div>

			<ErrorContainer
				alert={t("user.__loginError__")}
				success={"Connexion réussie."}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>
			{rgpdError ? (
				<p className="alert">
					{t("common.__errorOccured__")} {t("common.__tryAgain__")}
				</p>
			) : null}
			<section id="login-form">
				<form onSubmit={handleSubmit(onSubmit)}>
					<label>{t("common.__login__")} :</label>
					<small id="loginHelp" className="error">
						{errors.login && t("common.__requiredInput__")}
					</small>
					<input type="text" id="login" aria-describedby="loginHelp" {...register("login", { required: true })} />
					<div className="space"></div>
					<label>{t("user.__password__")} :</label>
					<small id="loginHelp" className="error">
						{errors.password && t("common.__requiredInput__")}
					</small>
					<div className="input-password">
						<input
							type={viewPassword ? "text" : "password"}
							aria-describedby="passwordHelp"
							{...register("password", { required: true })}
							id="password"
						/>
						<button className="view-password" type="button" onClick={() => setViewPassword(prevViewPassword => !prevViewPassword)}>
							{viewPassword ? <BsEyeSlash style={{ height: "1.5rem" }} /> : <BsEye style={{ height: "1.5rem" }} />}
						</button>
					</div>

					<div className="center">
						<br />
						<Link to={"/lostpassword"}>
							<p className="meta-link" role="button">
								{t("user.__lostPassword__")}
							</p>
						</Link>
						<button type="submit" className="home-button">
							{t("home.__connexion__")}
						</button>
					</div>
				</form>
			</section>
			<Modal rgpdModal={rgpdModal} setRgpdModal={setRgpdModal} />
			<br />
			<Footer />
		</>
	);
};

export default Login;
