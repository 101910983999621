import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import UserStateInterface from "../../interfaces/UserStateInterface";
import DocumentsWithSearch from "./DocumentsWithSearch";
import HeaderContainer from "../../Layout/HeaderContainer";

interface SeniorDocumentsProps {
	userDispatch: Function;
	isPrincipal: boolean;
	seniorSelectedId: number;
	msgDispatch: Function;
	msgStore: any;
	userState: UserStateInterface;
}

export default function SeniorDocuments({
	userState,
	userDispatch,
	msgStore,
	msgDispatch,
	isPrincipal,
	seniorSelectedId
}: SeniorDocumentsProps) {
	const { t } = useTranslation("common");

	useEffect(() => {
		if (seniorSelectedId) {
			http.get(`${url.BASEURL}/seniors/${seniorSelectedId}/all/unread/count`).then(res => {
				userDispatch({
					type: "UPDATE_COUNTERS",
					payload: { senior: seniorSelectedId, counters: res.data }
				});
			});
		}
	}, [seniorSelectedId]);

	useEffect(() => {
		if (seniorSelectedId && isPrincipal) {
			http
				.get(`${url.SENIORS}/${seniorSelectedId}/documents`)
				.then(res => {
					msgDispatch({
						type: "GET_PERSONAL_DOCUMENTS",
						payload: res.data.senior_documents
					});
				})
				.catch(() => {
					msgDispatch({
						type: "GET_PERSONAL_DOCUMENTS",
						payload: []
					});
				});
		}
		// eslint-disable-next-line
	}, [seniorSelectedId, isPrincipal]);

	return (
		<>
			<HeaderContainer title={t("mailbox.__personalDocuments__")} />
			{msgStore.seniorDocuments.length > 0 ? (
				<DocumentsWithSearch
					documents={msgStore.seniorDocuments}
					userLocale={userState.locale}
					type="senior"
					seniorSelectedId={seniorSelectedId}
					userDispatch={userDispatch}
				/>
			) : (
				<p>{t("mailbox.__noDocument__")}</p>
			)}
		</>
	);
}
