import React from "react";
import { BsXLg } from "react-icons/bs";

interface ErrorContainerProps {
	alert: string;
	success: string;
	httpResponse: { error: boolean } | null;
	setHttpResponse: Function;
}

export default function ErrorContainer({ alert, success, httpResponse, setHttpResponse }: ErrorContainerProps) {
	return (
		<>
			{httpResponse ? (
				httpResponse.error ? (
					<p className="alert">
						<span className="right error close-btn" onClick={() => setHttpResponse(null)}>
							<BsXLg style={{ verticalAlign: "middle" }} />
						</span>
						<span style={{ paddingLeft: "0.7rem" }}>{alert}</span>
					</p>
				) : (
					<p className="success">
						<span className="right close-btn" onClick={() => setHttpResponse(null)}>
							<BsXLg style={{ verticalAlign: "middle" }} />
						</span>
						<span style={{ paddingLeft: "0.7rem" }}>{success}</span>
					</p>
				)
			) : null}
		</>
	);
}
