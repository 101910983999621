import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import MyInputTextarea from "../../utils/form/MyInputTextarea";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import UserStateInterface from "../../interfaces/UserStateInterface";
import { formatedDateFromString } from "../../utils/Datetime";
import { BsXLg } from "react-icons/bs";

interface RejectVisioProps {
	show: {
		show: boolean;
		info: {
			visioId: number;
			visioDate: string;
		} | null;
	};
	setShow: Function;
	userDispatch: Function;
	seniorSelectedId: number;
	userState: UserStateInterface;
	msgDispatch?: Function;
	setVisioIsRejected?: Function;
}

const RejectVisio = ({
	show,
	setShow,
	userState,
	userDispatch,
	seniorSelectedId,
	msgDispatch,
	setVisioIsRejected
}: RejectVisioProps) => {
	const { t } = useTranslation("common");
	const { handleSubmit } = useFormContext<{ motif: string }>();
	const visioId = show.info?.visioId;
	const visio = show.info?.visioDate;
	const [error, setError] = useState(false);

	const onSubmit = (values: { motif: string }) => {
		setError(false);
		const data = {
			message_id: visioId,
			message: values.motif
		};
		http
			.post(`${url.HOMES}/visio/reject`, data)
			.then(() => {
				userDispatch({
					type: "READ_MESSAGE",
					payload: { senior: seniorSelectedId, nb: 1 }
				});
				if (msgDispatch) {
					msgDispatch({
						type: "FAMILY_REJECT_VISIO",
						payload: visioId
					});
				}
				if (setVisioIsRejected) {
					setVisioIsRejected({ rejected: true });
				}
				setShow({ show: false, info: null });
			})
			.catch(() => setError(true));
	};

	return (
		<>
			<div className="center">
				<h4>
					<strong>{t("mailbox.__rejectVisio__")}</strong>
				</h4>
				{visio ? (
					<p>
						{t("mailbox.__cancelThisVisio__")} :
						<br />
						<b>{formatedDateFromString(visio, "PPPPp", userState.locale)} </b>
					</p>
				) : null}
			</div>
			{error ? (
				<p className="alert">
					<span className="right close-btn">
						<BsXLg style={{ verticalAlign: "middle" }} role="button" onClick={() => setError(false)} />
					</span>
					{t("common.__errorOccured__")}
				</p>
			) : null}
			<form onSubmit={handleSubmit(onSubmit)}>
				<MyInputTextarea label="Veuillez saisir un motif :" name="motif" behavior={{ required: true }} rows="4" />
				<button className="dark-button">{t("common.__send__")}</button>
			</form>
		</>
	);
};

export default RejectVisio;
