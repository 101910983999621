import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsFillBackspaceFill, BsArrowReturnRight, BsXCircle } from "react-icons/bs";
import "./UserSettings.scss";
import UpdateAffiliate from "./UpdateAffiliate";
import UserStateInterface from "../../interfaces/UserStateInterface";
import http from "../../Services/Interceptor.js";
import * as url from "../../Constants/Url";

type AffiliatesType =
	| {
			id: number;
			first_name: string;
			last_name: string;
			email: string;
			login: string;
			seniors: {
				id: number;
				first_name: string;
				last_name: string;
			}[];
	  }[]
	| [];

interface ShowUpdateModalInterface {
	show: boolean;
	affiliate: {
		id: number;
		first_name: string;
		last_name: string;
		email: string;
		login: string;
		seniors: {
			id: number;
			first_name: string;
			last_name: string;
		}[];
	} | null;
}

interface UpdateAffiliateModalInterface {
	setShowUpdateModal: Function;
	showUpdateModal: ShowUpdateModalInterface;
}

const UserAffiliates = ({ userState }: { userState: UserStateInterface }) => {
	const { t } = useTranslation("common");
	const [showUpdateModal, setShowUpdateModal] = useState<ShowUpdateModalInterface>({
		show: false,
		affiliate: null
	});
	const [deleteError, setDeleteError] = useState(false);
	const [removed, setRemoved] = useState(false);
	const [affiliates, setAffiliates] = useState<AffiliatesType>([]);

	const removeAffiliate = (affiliateId: number) => {
		setDeleteError(false);
		http
			.delete(`${url.FAMILY_URL}/${affiliateId}`)
			.then(() => setRemoved(prevRemoved => !prevRemoved))
			.catch(() => setDeleteError(true));
	};

	useEffect(() => {
		http
			.get(`${url.FAMILY_URL}?seniors=true`)
			.then(res => setAffiliates(res.data.family_users))
			.catch(() => setAffiliates([]));
		// eslint-disable-next-line
	}, [removed, showUpdateModal]);

	// MODAL to update an existing affiliate user
	const UpdateAffiliateModal = ({ showUpdateModal, setShowUpdateModal }: UpdateAffiliateModalInterface) => {
		return showUpdateModal.show ? (
			<div className="overlay">
				<div className="modal">
					<div className="modal-close" role="button" onClick={() => setShowUpdateModal({ show: false, affiliate: null })}>
						<BsXCircle style={{ height: "1rem" }} />
					</div>
					<div className="modal-body">
						<UpdateAffiliate showUpdateModal={showUpdateModal} setShowUpdateModal={setShowUpdateModal} userState={userState} />
					</div>
				</div>
			</div>
		) : null;
	};

	return (
		<>
			<div className="card">
				<div className="center">
					<h2>{t("user.__affiliates__")}</h2>
					<div className="separator"></div>
				</div>
				{affiliates.length > 0 ? (
					<>
						{deleteError ? (
							<div className="alert">
								<span className="right" onClick={() => setDeleteError(false)}>
									X
								</span>
								<p>{t("user.__removeUserError__")}</p>
							</div>
						) : null}

						<ul>
							{affiliates.map(affiliate => (
								<li key={affiliate.id} className="mt-5">
									<BsFillBackspaceFill role="button" className="right btn-icon" onClick={() => removeAffiliate(affiliate.id)} />
									<p className="inline affiliate-link" onClick={() => setShowUpdateModal({ show: true, affiliate: affiliate })}>
										{affiliate.first_name} {affiliate.last_name}
									</p>
									<p className="meta">
										{t("common.__mail__")} {affiliate.email}
									</p>
									<ul className="affiliates-list mt-2">
										{affiliate.seniors.map(s => (
											<li key={s.id}>
												<p className="meta ">
													<BsArrowReturnRight color="#828282" className="nav-icon" /> {s.first_name} {s.last_name}
												</p>
											</li>
										))}
									</ul>
								</li>
							))}
						</ul>
					</>
				) : (
					<p>{t("user.__askToAddUser__")}</p>
				)}
				<div className="center mt-5">
					<Link to={"/settings/addaffiliate"}>
						<button type="button" className="light-button">
							{t("user.__addUser__")}
						</button>
					</Link>
				</div>
				<br />
			</div>
			<UpdateAffiliateModal showUpdateModal={showUpdateModal} setShowUpdateModal={setShowUpdateModal} />
		</>
	);
};

export default UserAffiliates;
