import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-grid-system";
import "./UserSettings.scss";
import * as url from "../../Constants/Url";
import Select from "react-select";
import useAxios from "../../hooks/useAxios";

interface HandleTimezoneProps {
	timezone: { value: string; label: string };
	setTimezone: Function;
	familyUserTimezone: { value: string; label: string };
}

const HandleTimezone = ({ timezone, setTimezone, familyUserTimezone }: HandleTimezoneProps) => {
	const { t } = useTranslation("common");

	const { data, loading } = useAxios({ url: `${url.BASEURL}/timezones` });
	const timezoneList = data ? data.timezones : [];
	const timezones =
		timezoneList && timezoneList.length > 0
			? timezoneList.map((timezone: string) => ({ value: timezone, label: timezone.split("/").join(" / ") }))
			: [];

	return (
		<>
			<Col xs={12} sm={6} md={4} lg={4}>
				<p>{t("common.__timezone__")} :</p>
			</Col>
			<Col xs={12} sm={6} md={8} lg={8}>
				<Select
					placeholder={t("common.__timezone__")}
					name="colors"
					options={loading ? t("common.__loading__") : timezones}
					className="basic-multi-select mb-3"
					classNamePrefix="select"
					onChange={(e: any) => setTimezone(e)}
					value={timezone ? timezone : familyUserTimezone}
					isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
					menuPlacement="top"
				/>
			</Col>
		</>
	);
};

export default HandleTimezone;
