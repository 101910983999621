import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./PhotoFrame.scss";
import UploadPictures from "./UploadPictures";
import ProgressBar from "./ProgressBar";
import Gallery from "./Gallery";
import { BsTrash, BsXLg } from "react-icons/bs";
import http from "../../../Services/Interceptor";
import * as url from "../../../Constants/Url";
import UserStateInterface from "../../../interfaces/UserStateInterface";
import Loader from "../../../Layout/Loader";
import HeaderContainer from "../../../Layout/HeaderContainer";

export default function PhotoFrame({ userState }: { userState: UserStateInterface }) {
	const { t } = useTranslation("common");
	const [picturesDeleted, setPicturesDeleted] = useState({ success: false, error: false, nb: 0 });
	const [picturesUploaded, setPicturesUploaded] = useState({
		success: false,
		error: false,
		upload: false,
		nb: 0
	});
	const seniorSelectedId = userState.seniorSelectedId;
	const userId = userState.familyUser.id;
	const isPrincipal = userState.familyUser.principal;
	const [stockageSize, setStockageSize] = useState(0);
	const [stockageQuantity, setStockageQuantity] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const handleAddPictures = () => {
		setPicturesUploaded({ success: false, error: false, upload: true, nb: 0 });
		setPicturesDeleted({ success: false, error: false, nb: 0 });
	};

	useEffect(() => {
		if (seniorSelectedId) {
			http
				.get(`${url.SENIORS}/${seniorSelectedId}/photo_frame_items/quota`)
				.then(res => {
					setStockageSize(res.data.photo_frame.size);
					setStockageQuantity(res.data.photo_frame.nb);
				})
				.catch(() => {
					setStockageSize(0);
					setStockageQuantity(0);
				});
		}
	}, [seniorSelectedId, picturesDeleted, picturesUploaded]);

	return (
		<div className="content-card">
			<HeaderContainer title={t("link.__photoFrame__")} />
			{isLoading ? (
				<Loader />
			) : (
				<>
					{picturesUploaded.upload ? null : (
						<button type="button" className="dark-button right" onClick={() => handleAddPictures()}>
							{t("link.__addPicts__")}
						</button>
					)}

					<ProgressBar stockageSize={stockageSize} stockageQuantity={stockageQuantity} />

					{picturesDeleted.error ? (
						<p className="alert">
							{t("link.__removePictsError__")}
							<span className="right fake-link" onClick={() => setPicturesDeleted({ success: false, error: false, nb: 0 })}>
								<BsXLg style={{ verticalAlign: "middle" }} />
							</span>
						</p>
					) : null}
					{picturesDeleted.success ? (
						<p className="success">
							<BsTrash className="timeline-icon" color="#4a4e64" />
							{picturesDeleted.nb === 1
								? `${picturesDeleted.nb} photo supprimée.`
								: `${picturesDeleted.nb} photos supprimées avec succès.`}
							<span className="right fake-link" onClick={() => setPicturesDeleted({ success: false, error: false, nb: 0 })}>
								<BsXLg style={{ verticalAlign: "middle" }} />
							</span>
						</p>
					) : null}

					{picturesUploaded.error ? (
						<p className="alert">
							{t("link.__addPictsError__")}
							<span
								className="right fake-link"
								role="button"
								onClick={() => setPicturesUploaded({ success: false, error: false, upload: false, nb: 0 })}
							>
								<BsXLg style={{ verticalAlign: "middle" }} />
							</span>
						</p>
					) : null}
					{picturesUploaded.success ? (
						<p className="success">
							{picturesUploaded.nb} {t("link.__addPictsSuccess__")}
							<span
								className="right fake-link"
								role="button"
								onClick={() => setPicturesUploaded({ success: false, error: false, upload: false, nb: 0 })}
							>
								<BsXLg style={{ verticalAlign: "middle" }} />
							</span>
						</p>
					) : null}
					{picturesUploaded.upload ? (
						<UploadPictures
							seniorSelectedId={seniorSelectedId}
							setPicturesUploaded={setPicturesUploaded}
							setIsLoading={setIsLoading}
						/>
					) : stockageQuantity === 0 ? (
						<div className="mood-card">
							<p>
								<b>{t("link.__photoframeWarningOne__")}</b>
							</p>
							<p style={{ marginTop: "1.25rem" }}>{t("link.__photoframeWarningTwo__")}</p>

							<button type="button" className="pink-button" style={{ marginTop: "1.25rem" }} onClick={() => handleAddPictures()}>
								{t("link.__addPicts__")}
							</button>
						</div>
					) : (
						<Gallery
							seniorSelectedId={seniorSelectedId}
							setPicturesDeleted={setPicturesDeleted}
							setPicturesUploaded={setPicturesUploaded}
							userId={userId}
							isPrincipal={isPrincipal}
						/>
					)}
				</>
			)}
		</div>
	);
}
