import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsCheck, BsFillBackspaceFill } from "react-icons/bs";
import { ErrorSuccessContext } from "../../../contexts/ErrorSuccessContext";
import AddLifestory from "./AddLifestory";
import HandleErrorSuccess from "../../../Layout/HandleErrorSuccess";
import UserStateInterface from "../../../interfaces/UserStateInterface";
import http from "../../../Services/Interceptor";
import * as url from "../../../Constants/Url";
import { formatedDateFromString } from "../../../utils/Datetime";
import HeaderContainer from "../../../Layout/HeaderContainer";

interface StoryInterface {
	id: number;
	life_story: string;
	moderated_at: string;
	accepted: boolean | null;
}

const SeniorStories = ({ userState }: { userState: UserStateInterface }) => {
	const { t } = useTranslation("common");
	const { esState, esDispatch } = useContext(ErrorSuccessContext);
	const seniorSelectedId = userState.seniorSelectedId;
	const [update, setUpdate] = useState(false);
	const [addMode, setAddMode] = useState(false);
	const [allLifestories, setAllLifestories] = useState<StoryInterface[]>([]);

	useEffect(() => {
		if (seniorSelectedId) {
			http
				.get(`${url.SENIORS}/${seniorSelectedId}/life_stories`)
				.then(res => setAllLifestories(res.data.senior_life_stories))
				.catch(() => {
					esDispatch({
						type: "DISPLAY_ERROR",
						msg: t("common.__noData__")
					});
				});
		}
		// eslint-disable-next-line
	}, [seniorSelectedId, update, addMode]);

	useEffect(() => {
		return function cleanup() {
			esDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	const handleLifestories = () => {
		const filteredLifestories = allLifestories.filter(stories => stories.accepted === true);
		if (filteredLifestories.length > 0) {
			return (
				<ul className="stories-list">
					<h4 style={{ marginBottom: 0 }}>{t("link.__bioAccepted__")}</h4>
					{filteredLifestories.map((story: StoryInterface) => (
						<li key={story.id}>
							<p
								className={userState.familyUser.principal ? "inline story" : "inline"}
								onClick={() => deleteStory(story.life_story, story.id)}
							>
								{story.life_story}
							</p>
							{userState.familyUser.principal ? (
								<p className="inline right suppr" onClick={() => deleteStory(story.life_story, story.id)}>
									<BsFillBackspaceFill />
								</p>
							) : null}
							{story.moderated_at ? (
								<p className="meta">
									<BsCheck className="check-icon blue" /> Validée le :{" "}
									{formatedDateFromString(story.moderated_at, "PPPP", userState.locale)}
								</p>
							) : null}
						</li>
					))}
					<br />
				</ul>
			);
		} else {
			<p>{t("link.__noBioAccepted__")}</p>;
		}
	};

	const handleRejectedLifeStories = () => {
		const filteredLifestories = allLifestories.filter(stories => stories.accepted === false);
		if (filteredLifestories.length > 0) {
			return (
				<ul className="stories-list">
					<h4 style={{ marginBottom: 0 }}>{t("link.__bioRefused__")}</h4>
					<div className="story-card">
						<p>{t("link.__bioRefusedInfo__")}</p>
					</div>
					<br />
					{filteredLifestories.map((story: StoryInterface) => (
						<li key={story.id}>
							<p className="inline story" onClick={() => deleteStory(story.life_story, story.id)}>
								{story.life_story}
							</p>
							<p className="inline right suppr" onClick={() => deleteStory(story.life_story, story.id)}>
								<BsFillBackspaceFill />
							</p>

							{story.moderated_at ? (
								<p className="meta">
									{t("link.__moderationDate__")} {formatedDateFromString(story.moderated_at, "PPPP", userState.locale)}
								</p>
							) : null}
						</li>
					))}
					<br />
				</ul>
			);
		} else {
			return;
		}
	};

	const handlePendingLifeStories = () => {
		const filteredLifestories = allLifestories.filter(stories => stories.accepted === null);
		if (filteredLifestories.length > 0) {
			return (
				<ul className="stories-list">
					<h4 style={{ marginBottom: 0 }}>{t("link.__bioPending__")}</h4>
					{filteredLifestories.map((story: StoryInterface) => (
						<li key={story.id}>
							<p className="inline story" onClick={() => deleteStory(story.life_story, story.id)}>
								{story.life_story}
							</p>
							<p className="inline right suppr" onClick={() => deleteStory(story.life_story, story.id)}>
								<BsFillBackspaceFill />
							</p>
						</li>
					))}
					<br />
				</ul>
			);
		} else {
			return;
		}
	};

	// Remove Life Story
	const deleteStory = (lifestory: string, lifestoryId: number) => {
		esDispatch({
			type: "RESET_ES"
		});
		const data = {
			_method: "DELETE",
			senior_id: seniorSelectedId,
			life_story: lifestory
		};

		http
			.post(`${url.SENIORS}/life_stories/${lifestoryId}`, data)
			.then(() => {
				esDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("link.__removeBioSuccess__")
				});
				setUpdate(prevUpdate => !prevUpdate);
			})
			.catch(() => {
				esDispatch({
					type: "DISPLAY_ERROR",
					payload: t("link.__removeBioError__")
				});
			});
	};

	return (
		<div className="content-card">
			<HeaderContainer title={t("link.__bio__")} />

			<div className="space-between">
				<p className="meta mt-2">{t("link.__bioWarning__")}</p>
				{userState.familyUser.principal && allLifestories.length === 0 ? null : addMode ? (
					<button type="button" className="light-button" onClick={() => setAddMode(false)}>
						{t("common.__cancel__")}
					</button>
				) : (
					<button type="button" className="dark-button" onClick={() => setAddMode(true)}>
						{t("common.__add__")}
					</button>
				)}
			</div>
			{userState.familyUser.principal &&
				(allLifestories.length > 0 ? null : (
					<button type="button" className="dark-button" onClick={() => setAddMode(true)}>
						{t("common.__add__")}
					</button>
				))}

			<HandleErrorSuccess esState={esState} esDispatch={esDispatch} />

			{addMode ? (
				<AddLifestory setAddMode={setAddMode} seniorSelectedId={seniorSelectedId} esDispatch={esDispatch} />
			) : (
				<>
					{handleLifestories()}
					{userState.familyUser.principal ? (
						<>
							{handlePendingLifeStories()}
							{handleRejectedLifeStories()}
						</>
					) : null}
				</>
			)}
		</div>
	);
};

export default SeniorStories;
