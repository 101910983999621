import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import UserStateInterface from "../../interfaces/UserStateInterface";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import Loader from "../../Layout/Loader";
import NoDataAvailable from "../../Layout/NoDataAvailable";
import FlowActivity from "./FlowActivity";
import FlowMessages from "./FlowMessages";
import FlowDocument from "./FlowDocument";
import FlowLinkMessage from "./FlowLinkMessages";
import SocialNewsContainer from "../Blog/SocialNewsContainer";
import * as types from "../../Constants/FlowTypes";

interface FlowContainerProps {
	userDispatch: Function;
	userState: UserStateInterface;
}

export default function FlowContainer({ userState, userDispatch }: FlowContainerProps) {
	const { t } = useTranslation("common");
	const seniorSelectedId = userState.seniorSelectedId;

	const { status, data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
		["flow", seniorSelectedId],
		async ({ pageParam = 0 }) => {
			const res = await http.get(`${url.SENIORS}/${seniorSelectedId}/flow/` + pageParam);
			return res.data;
		},
		{
			getPreviousPageParam: firstPage => firstPage.previousId ?? false,
			getNextPageParam: lastPage => lastPage.nextId ?? false
		}
	);

	const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
	useIntersectionObserver({
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage
	});

	const handleData = (post: any) => {
		switch (post.type) {
			case types.FAMILY_USER_MESSAGE:
				return (
					<FlowMessages
						message={post.family_user_message}
						userState={userState}
						seniorSelectedId={seniorSelectedId}
						userDispatch={userDispatch}
					/>
				);
			case types.HOME_BLOG_POST:
				return <SocialNewsContainer userState={userState} seniorSelectedId={seniorSelectedId} post={post.home_blog_post} />;
			case types.SENIOR_MESSAGE:
				return (
					<FlowLinkMessage
						seniorSelectedId={seniorSelectedId}
						userDispatch={userDispatch}
						message={post.senior_message}
						userLocale={userState.locale}
					/>
				);
			case types.HOME_DOCUMENT:
				return (
					<FlowDocument
						document={post.home_document}
						userLocale={userState.locale}
						type={"home"}
						seniorSelectedId={seniorSelectedId}
						userDispatch={userDispatch}
					/>
				);
			case types.SENIOR_DOCUMENT:
				return (
					<FlowDocument
						document={post.senior_document}
						userLocale={userState.locale}
						type={"senior"}
						seniorSelectedId={seniorSelectedId}
						userDispatch={userDispatch}
					/>
				);
			case types.ACTIVITY:
				return <FlowActivity activity={post.activity} userLocale={userState.locale} />;
			default:
				return;
		}
	};

	return (
		<>
			{status === "loading" ? (
				<Loader />
			) : status === "error" ? (
				<NoDataAvailable />
			) : data ? (
				data.pages[0].data.length > 0 ? (
					<div style={{ marginTop: "15px" }}>
						{data.pages.map(group =>
							group.data.map((post: any, index: string) => (
								<div key={index} className="mt-2">
									{handleData(post)}
								</div>
							))
						)}
						<div className="center">
							<button
								ref={loadMoreButtonRef}
								onClick={() => fetchNextPage()}
								disabled={!hasNextPage || isFetchingNextPage}
								className="light-button"
							>
								{isFetchingNextPage ? t("common.__loading__") : hasNextPage ? t("common.__more__") : null}
							</button>
							{isFetching && !isFetchingNextPage ? t("common.__loading__") : null}
						</div>
					</div>
				) : (
					<p className="meta mb-5">{t("news.__noNews__")}</p>
				)
			) : (
				<NoDataAvailable />
			)}
		</>
	);
}
