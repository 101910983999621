import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
	BsBellFill,
	BsCalendarWeek,
	BsCamera,
	BsCameraVideo,
	BsChatDots,
	BsClipboardCheck,
	BsEnvelope,
	BsImages,
	BsInfoCircle
} from "react-icons/bs";
import useWindowSize from "../hooks/useWindowSize";
import UserStateInterface from "../interfaces/UserStateInterface";
import { Tooltip } from "../utils/Tooltip";
import { displayAvatar } from "../interfaces/DisplayAvatar";
import { FamilyUserContext } from "../contexts/FamilyUserContext";
import SeniorHomeEvents from "../components/HomeInfo/SeniorHomeEvents";

interface SidebarProps {
	userState: UserStateInterface;
	userDispatch: Function;
}

interface SeniorInterface {
	id: number;
	active: boolean;
	home_id: number;
	avatar_updated: boolean;
	gender: string;
	e4linked: boolean;
	first_name: string;
	last_name: string;
	nb_unread_home_document: number;
	nb_unread_message: number;
	nb_unread_senior_document: number;
	nb_unread_senior_message: number;
}

const Sidebar = ({ userState, userDispatch }: SidebarProps) => {
	const { t } = useTranslation("common");
	const size = useWindowSize();
	const { seniorHome } = useContext(FamilyUserContext);

	const handleSeniors = (id: number) => {
		localStorage.setItem("seniorSelectedId", JSON.stringify(id));
		userDispatch({
			type: "SET_SENIOR",
			payload: id
		});
	};

	const mySeniors: SeniorInterface[] = userState.familyUser.seniors;
	const seniorSelectedId: number = userState.seniorSelectedId;
	const displaySelectedSeniorFirst = mySeniors.find(senior => senior.id === seniorSelectedId);
	const seniorSelectedName: string = displaySelectedSeniorFirst ? displaySelectedSeniorFirst.first_name : "";
	const seniorsUnselected = mySeniors.filter(senior => senior.id !== seniorSelectedId);
	const handleShortcuts = (isE4linked: boolean) => {
		return (
			<div className="shorcuts-sidebar">
				<Tooltip text={t("mailbox.__askForVisio__")}>
					<Link to="/messages/2">
						<BsCameraVideo className="shortcut-icon" />
					</Link>
				</Tooltip>
				{isE4linked ? (
					<>
						<Tooltip text={t("common.__timeline__")}>
							<Link to="/activities">
								<BsClipboardCheck className="shortcut-icon" />
							</Link>
						</Tooltip>
						<Tooltip text={t("link.__photoFrame__")}>
							<Link to="/profile/3">
								<BsImages className="shortcut-icon" />
							</Link>
						</Tooltip>
						<Tooltip text={`${t("link.__writeTo__")} ${seniorSelectedName}`}>
							<Link to="/profile/6">
								<BsChatDots className="shortcut-icon" />
							</Link>
						</Tooltip>
					</>
				) : (
					<Tooltip text={t("common.__mailbox__")}>
						<Link to="/messages/1">
							<BsEnvelope className="shortcut-icon" />
						</Link>
					</Tooltip>
				)}
				<Tooltip text={t("calendar.__openCalendar__")}>
					<Link to="/calendar">
						<BsCalendarWeek className="shortcut-icon" />
					</Link>
				</Tooltip>
				<Tooltip text={t("home.__home__")}>
					<Link to="/seniorhome">
						<BsInfoCircle className="shortcut-icon" />
					</Link>
				</Tooltip>
			</div>
		);
	};

	return size.width > 768 ? (
		<div className="sidebar">
			{displaySelectedSeniorFirst ? (
				<div className="card" key={displaySelectedSeniorFirst.id}>
					<div className="center avatar-component">
						{displayAvatar(
							displaySelectedSeniorFirst.avatar_updated,
							displaySelectedSeniorFirst.gender,
							displaySelectedSeniorFirst.id,
							"avatar"
						)}
						{userState.familyUser.principal ? (
							<Link to="/avatar">
								<BsCamera className="avatar-button" role="button" />
							</Link>
						) : null}
					</div>
					<div className="senior-nav-name">
						<p className="center mb-5">
							<b>
								{displaySelectedSeniorFirst.first_name} {displaySelectedSeniorFirst.last_name}
							</b>
						</p>
					</div>
					{handleShortcuts(displaySelectedSeniorFirst.e4linked)}
				</div>
			) : null}
			{seniorsUnselected
				? seniorsUnselected.map(senior => (
						<div className="sidebar-unselected-senior-card" key={senior.id} onClick={() => handleSeniors(senior.id)} role="button">
							<div className="space-between">
								{displayAvatar(senior.avatar_updated, senior.gender, senior.id, "avatar-nav")}
								<p className="senior-nav-name mt-2">
									{senior.first_name} {senior.last_name}
									{mySeniors.length > 1 ? (
										senior.nb_unread_message > 0 ||
										senior.nb_unread_senior_document > 0 ||
										senior.nb_unread_home_document > 0 ||
										senior.nb_unread_senior_message > 0 ? (
											<BsBellFill className={seniorSelectedId === senior.id ? "senior-notification red" : "senior-notification grey"} />
										) : null
									) : null}
								</p>
							</div>
						</div>
				  ))
				: null}
			<SeniorHomeEvents userState={userState} seniorHome={seniorHome} />
		</div>
	) : (
		<div className="responsive-sidebar">
			{displaySelectedSeniorFirst ? (
				<div className="sidebar-responsive-card">
					<div style={{ display: "flex" }}>
						{userState.familyUser.principal ? (
							<Link to="/avatar">
								{displayAvatar(
									displaySelectedSeniorFirst.avatar_updated,
									displaySelectedSeniorFirst.gender,
									displaySelectedSeniorFirst.id,
									"avatar-sidebar"
								)}
							</Link>
						) : (
							displayAvatar(
								displaySelectedSeniorFirst.avatar_updated,
								displaySelectedSeniorFirst.gender,
								displaySelectedSeniorFirst.id,
								"avatar-sidebar"
							)
						)}
						<div className="sidebar-responsive-shortcuts" style={{ marginLeft: "0.5rem" }}>
							<p className="senior-nav-name mt-2">
								<b>
									{displaySelectedSeniorFirst.first_name} {displaySelectedSeniorFirst.last_name}
								</b>
							</p>
							{handleShortcuts(displaySelectedSeniorFirst.e4linked)}
						</div>
					</div>
				</div>
			) : null}
			{seniorsUnselected
				? seniorsUnselected.map(senior => (
						<div
							className="sidebar-responsive-card-unselected"
							key={senior.id}
							role="button"
							onClick={() => handleSeniors(senior.id)}
						>
							<p>
								{senior.first_name} {senior.last_name}
							</p>
							{senior.nb_unread_message > 0 ||
							senior.nb_unread_senior_document > 0 ||
							senior.nb_unread_home_document > 0 ||
							senior.nb_unread_senior_message > 0 ? (
								<BsBellFill className="senior-notification grey" />
							) : null}
						</div>
				  ))
				: null}
		</div>
	);
};
export default Sidebar;
