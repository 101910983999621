import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-grid-system";
import { BsCheckLg } from "react-icons/bs";
import "./UserSettings.scss";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import * as contentType from "../../Constants/NotificationsConstants";
import ErrorContainer from "../../Layout/ErrorContainer";
import UserStateInterface from "../../interfaces/UserStateInterface";
import HeaderContainer from "../../Layout/HeaderContainer";

interface MySettingsProps {
	error: boolean;
	userDispatch: Function;
	setEditMode: Function;
	isE4linked: boolean;
	isPrincipal: boolean;
	userState: UserStateInterface;
}

interface UserDataInterface {
	family_user: {
		first_name: string;
		last_name: string;
		phone: string;
		email: string;
		locale: string;
		messagings: Array<Object>;
		timezone: string;
	};
}

const MySettings = ({ error, userState, userDispatch, setEditMode, isE4linked, isPrincipal }: MySettingsProps) => {
	const { t } = useTranslation("common");
	const familyUser = userState.familyUser;
	const messagings = userState.familyUser.messagings;
	const subscriptions = messagings.length > 0 ? messagings[0].choices : [];
	const [newMail, setNewMail] = useState(messagings.length > 0 ? messagings[0].email : userState.familyUser.email);
	const [httpResponse, setHttpResponse] = useState<{ error: boolean } | null>(null);

	const subscribeTo = (contentType: number) => {
		setHttpResponse(null);
		const contentSubscriptions =
			subscriptions.length > 0
				? subscriptions.includes(contentType)
					? subscriptions.filter(elt => elt !== contentType)
					: [...subscriptions, contentType]
				: [contentType];

		const userData = {
			family_user: {
				first_name: userState.familyUser.first_name,
				last_name: userState.familyUser.last_name,
				phone: userState.familyUser.phone,
				email: userState.familyUser.email,
				locale: userState.familyUser.locale,
				messagings: [
					{
						enabled: true,
						type: "EMAIL",
						email: newMail,
						choices: contentSubscriptions
					}
				],
				timezone: userState.familyUser.timezone
			}
		};
		const subscription = true;
		updateAccount(userData, subscription);
	};

	const handleNewMail = () => {
		setHttpResponse(null);
		const userData = {
			family_user: {
				first_name: userState.familyUser.first_name,
				last_name: userState.familyUser.last_name,
				phone: userState.familyUser.phone,
				email: userState.familyUser.email,
				locale: userState.familyUser.locale,
				messagings: [
					{
						enabled: true,
						type: "EMAIL",
						email: newMail,
						choices: subscriptions
					}
				],
				timezone: userState.familyUser.timezone
			}
		};
		const subscription = false;
		updateAccount(userData, subscription);
	};

	const updateAccount = (userData: UserDataInterface, subscription: boolean) => {
		http
			.put(`${url.BASEURL}/me`, userData)
			.then(res => {
				userDispatch({
					type: "UPDATE_USER",
					payload: res.data.family_user
				});
				if (!subscription) {
					setHttpResponse({ error: false });
				}
			})
			.catch(() => setHttpResponse({ error: true }));
	};

	const displayLocale = (locale: string) => {
		switch (locale) {
			case "fr":
				return t("common.__french__");
			case "en":
				return t("common.__english__");
			case "nl":
				return t("common.__dutch__");
			default:
				return;
		}
	};

	const button = () => {
		return (
			<button type="button" className="dark-button" onClick={() => setEditMode(true)}>
				{t("user.__updateMyAccount__")}
			</button>
		);
	};

	return (
		<div className="light-card">
			<HeaderContainer title={t("common.__myAccount__")} button={button} />

			{error ? (
				<p>{t("common.__noData__")}</p>
			) : (
				<Row>
					<Col xs={4} sm={6} md={4} lg={4}>
						<p>{t("common.__name__")}</p>
					</Col>
					<Col xs={8} sm={6} md={8} lg={8}>
						<p>
							{familyUser.first_name} {familyUser.last_name}
						</p>
					</Col>
					<Col xs={4} sm={6} md={4} lg={4}>
						<p>{t("common.__login__")}</p>
					</Col>
					<Col xs={8} sm={6} md={8} lg={8}>
						<p>{familyUser.login}</p>
					</Col>
					<Col xs={4} sm={6} md={4} lg={4}>
						<p>{t("common.__mail__")}</p>
					</Col>
					<Col xs={8} sm={6} md={8} lg={8}>
						<p>{familyUser.email}</p>
					</Col>
					<Col xs={4} sm={6} md={4} lg={4}>
						<p>{t("common.__phone__")}</p>
					</Col>
					<Col xs={8} sm={6} md={8} lg={8}>
						<p>{familyUser.phone}</p>
					</Col>
					<Col xs={12} sm={6} md={4} lg={4}>
						<p>{t("common.__language__")}</p>
					</Col>
					<Col xs={12} sm={6} md={8} lg={8}>
						<p>{displayLocale(familyUser.locale)}</p>
					</Col>
					<Col xs={12} sm={6} md={4} lg={4}>
						<p>{t("common.__timezone__")}</p>
					</Col>
					<Col xs={12} sm={6} md={8} lg={8}>
						<p>{familyUser.timezone}</p>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						<p className="mt-5">
							<strong>{t("user.__notifications__")} :</strong>
						</p>
						<ErrorContainer
							alert={t("common.__errorOccured__")}
							success={t("user.__updateUserAccountSuccess__")}
							httpResponse={httpResponse}
							setHttpResponse={setHttpResponse}
						/>
						<label>{t("user.__notificationsMail__")} :</label>
						<div style={{ display: "flex" }}>
							<input type="text" value={newMail} onChange={e => setNewMail(e.target.value)} style={{ marginRight: "10px" }} />
							<button type="button" className="light-button" onClick={handleNewMail}>
								<BsCheckLg style={{ verticalAlign: "middle" }} />
							</button>
						</div>
						<p className="meta mt-2">{t("user.__notificationsWarning__")} :</p>
						<div className="meta-checkbox mt-2">
							<input
								type="checkbox"
								checked={subscriptions.includes(contentType.NEW_HOME_BLOG_POST)}
								name="blogpost"
								onChange={() => subscribeTo(contentType.NEW_HOME_BLOG_POST)}
							/>
							{t("user.__newBlogPost__")}
						</div>
						<div className="meta-checkbox">
							<input
								type="checkbox"
								checked={subscriptions.includes(contentType.NEW_HOME_DOCUMENT)}
								name="homeDocument"
								onChange={() => subscribeTo(contentType.NEW_HOME_DOCUMENT)}
							/>
							{t("user.__newDocument__")}
						</div>
						{isPrincipal ? (
							<div className="meta-checkbox">
								<input
									type="checkbox"
									checked={subscriptions.includes(contentType.NEW_SENIOR_DOCUMENT)}
									name="seniorDocument"
									onChange={() => subscribeTo(contentType.NEW_SENIOR_DOCUMENT)}
								/>
								{t("user.__newPrivateDocument__")}
							</div>
						) : null}
						{isE4linked ? (
							<div className="meta-checkbox">
								<input
									type="checkbox"
									checked={subscriptions.includes(contentType.NEW_MESSAGE_FROM_SENIOR)}
									name="messageFromSenior"
									onChange={() => subscribeTo(contentType.NEW_MESSAGE_FROM_SENIOR)}
								/>
								{t("user.__newMessageFromSenior__")}
							</div>
						) : null}
						<div className="meta-checkbox">
							<input
								type="checkbox"
								checked={subscriptions.includes(contentType.NEW_MESSAGE_FROM_HOME)}
								name="messageFromHome"
								onChange={() => subscribeTo(contentType.NEW_MESSAGE_FROM_HOME)}
							/>
							{t("user.__newMessageFromHome__")}
						</div>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default MySettings;
