import React from "react";
import FlowContainer from "../components/Flow/FlowContainer";
import VisioButton from "../components/Flow/VisioButton";
import UserStateInterface from "../interfaces/UserStateInterface";
import ContentLayout from "../Layout/ContentLayout";

interface FlowProps {
	userDispatch: Function;
	userState: UserStateInterface;
}

export default function Flow({ userState, userDispatch }: FlowProps) {
	return (
		<ContentLayout userState={userState} userDispatch={userDispatch}>
			<VisioButton userState={userState} />
			<FlowContainer userState={userState} userDispatch={userDispatch} />
		</ContentLayout>
	);
}
