import React from "react";
import UploadAvatar from "../components/Link/UploadAvatar";
import UserStateInterface from "../interfaces/UserStateInterface";
import ContentLayout from "../Layout/ContentLayout";

interface AvatarProps {
	userState: UserStateInterface;
	userDispatch: Function;
}

export default function Avatar({ userState, userDispatch }: AvatarProps) {
	return (
		<ContentLayout userState={userState} userDispatch={userDispatch}>
			<UploadAvatar userState={userState} />
		</ContentLayout>
	);
}
