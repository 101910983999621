import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsEnvelope, BsCameraVideo, BsFolder, BsArchive } from "react-icons/bs";
import useWindowSize from "../../hooks/useWindowSize";
import UserStateInterface from "../../interfaces/UserStateInterface";

interface MediaNavProps {
	component: string;
	isPrincipal: boolean;
	userState: UserStateInterface;
}

const MailboxNav = ({ component, isPrincipal, userState }: MediaNavProps) => {
	const { t } = useTranslation("common");
	const size = useWindowSize();
	const mySeniors = userState.familyUser.seniors;
	const mySenior = mySeniors.find(senior => senior.id === userState.seniorSelectedId);

	return (
		<div className="responsive-nav-link">
			<Link to="/messages/1" className={component === "1" ? "link-nav-button-hover center" : "link-nav-button center"}>
				{mySenior ? (
					mySenior.nb_unread_message > 0 ? (
						<span className="media-header-notification">
							<BsEnvelope className="icon-actions center" color="#4A4E64" />
							<span className="media-notification">{mySenior.nb_unread_message}</span>
							{size.width > 500 ? <p className="meta center">{t("common.__mailbox__")}</p> : null}
						</span>
					) : (
						<>
							<BsEnvelope className="icon-actions center" color="#4A4E64" />
							{size.width > 500 ? <p className="meta center">{t("common.__mailbox__")}</p> : null}
						</>
					)
				) : null}
			</Link>
			{mySenior && mySenior.active ? (
				<Link to="/messages/2" className={component === "2" ? "link-nav-button-hover center" : "link-nav-button center"}>
					<BsCameraVideo className="icon-actions center" color="#4A4E64" />
					{size.width > 500 ? <p className="meta center">{t("mailbox.__askForVisio__")}</p> : null}
				</Link>
			) : null}
			{isPrincipal ? (
				size.width > 1024 ? (
					<Link to="/messages/3" className={component === "3" ? "link-nav-button-hover center" : "link-nav-button center"}>
						<BsEnvelope className="icon-actions center" color="#4A4E64" />
						<p className="meta center">{t("link.__newMsg__")}</p>
					</Link>
				) : null
			) : null}

			<Link to="/messages/4" className={component === "4" ? "link-nav-button-hover center" : "link-nav-button center"}>
				{mySenior ? (
					mySenior.nb_unread_home_document > 0 ? (
						<span className="media-header-notification">
							<BsArchive className="icon-actions center" color="#4A4E64" />
							<span className="media-notification">{mySenior.nb_unread_home_document}</span>
							{size.width > 500 ? <p className="meta center">{t("mailbox.__generalDocuments__")}</p> : null}
						</span>
					) : (
						<>
							<BsArchive className="icon-actions center" color="#4A4E64" />
							{size.width > 500 ? <p className="meta center">{t("mailbox.__generalDocuments__")}</p> : null}
						</>
					)
				) : null}
			</Link>

			{isPrincipal ? (
				<Link to="/messages/5" className={component === "5" ? "link-nav-button-hover center" : "link-nav-button center"}>
					{mySenior ? (
						mySenior.nb_unread_senior_document > 0 ? (
							<span className="media-header-notification">
								<BsFolder className="icon-actions center" color="#4A4E64" />
								<span className="media-notification">{mySenior.nb_unread_senior_document}</span>
								{size.width > 500 ? <p className="meta center">{t("mailbox.__personalDocuments__")}</p> : null}
							</span>
						) : (
							<>
								<BsFolder className="icon-actions center" color="#4A4E64" />
								{size.width > 500 ? <p className="meta center">{t("mailbox.__personalDocuments__")}</p> : null}
							</>
						)
					) : null}
				</Link>
			) : null}
		</div>
	);
};

export default MailboxNav;
