import React from "react";
import LiveMeetingJitsiMeet from "./LiveMeetingJitsiMeet";
import "./style.scss";
import { useParams } from "react-router-dom";
import UserStateInterface from "../../interfaces/UserStateInterface";

export default function LiveMeeting({ userState }: { userState: UserStateInterface }) {
	const { subject, room, token } = useParams<{ subject?: string; room?: string; token?: string }>();

	return (
		<div className="center">
			<h5>{subject}</h5>
			<LiveMeetingJitsiMeet room={room} token={token} subject={subject} locale={userState.locale} />
		</div>
	);
}
