import React from "react";
import PostCard from "../components/PostCard/PostCard";
import UserStateInterface from "../interfaces/UserStateInterface";

export default function PostCards({ userState }: { userState: UserStateInterface }) {
	return (
		<div className="content-container">
			<PostCard userState={userState} />
		</div>
	);
}
