import React, { useContext } from "react";
import { FamilyUserContext } from "../contexts/FamilyUserContext";
import Login from "../pages/Login";
import Footer from "./Footer";
import ResponsiveNav from "../components/Header/ResponsiveNav";
// import SeniorNav from "../components/Header/SeniorNav";

interface MainLayoutProps {
	children: React.ReactNode;
}

export default function MainLayout({ children }: MainLayoutProps) {
	const { userState } = useContext(FamilyUserContext);

	return userState.isLogged ? (
		<>
			<ResponsiveNav userState={userState} />
			{/* <SeniorNav userState={userState} userDispatch={userDispatch} /> */}
			<div className="container">{children}</div>
			<Footer />
		</>
	) : (
		<Login />
	);
}
