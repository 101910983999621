import React, { useState } from "react";
import { Row, Col } from "react-grid-system";
import "./UserSettings.scss";
import UserAffiliates from "./UserAffilates";
import MySettings from "./MySettings";
import UpdateUserSettings from "./UpdateUserSettings";
import UserStateInterface from "../../interfaces/UserStateInterface";
interface UserSettingsProps {
	error: boolean;
	userDispatch: Function;
	userState: UserStateInterface;
}

const UserSettings = ({ error, userState, userDispatch }: UserSettingsProps) => {
	const [editMode, setEditMode] = useState(false);
	const isPrincipal = userState.familyUser.principal;
	const seniors = userState.familyUser.seniors;
	const mySenior = seniors.find(senior => senior.id === userState.seniorSelectedId);
	const isE4linked = mySenior ? mySenior.e4linked : false;

	return (
		<Row>
			<Col xs={12} sm={12} md={7} lg={7}>
				{editMode ? (
					<UpdateUserSettings setEditMode={setEditMode} userState={userState} userDispatch={userDispatch} />
				) : (
					<MySettings
						setEditMode={setEditMode}
						error={error}
						userState={userState}
						userDispatch={userDispatch}
						isE4linked={isE4linked}
						isPrincipal={isPrincipal}
					/>
				)}
			</Col>
			<Col xs={12} sm={12} md={5} lg={5}>
				{isPrincipal ? <UserAffiliates userState={userState} /> : null}
			</Col>
		</Row>
	);
};

export default UserSettings;
