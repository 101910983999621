import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-grid-system";
import "./UserSettings.scss";
import LanguageSwitcher from "../../i18n/LanguageSwitcher";

interface HandleLocaleProps {
	language: string;
	setLanguage: Function;
}

const HandleLanguage = ({ language, setLanguage }: HandleLocaleProps) => {
	const { t } = useTranslation("common");

	const languages = [
		{
			id: 1,
			name: t("common.__french__"),
			locale: "fr"
		},
		{
			id: 2,
			name: t("common.__english__"),
			locale: "en"
		},
		{
			id: 3,
			name: t("common.__dutch__"),
			locale: "nl"
		}
	];

	return (
		<>
			<Col xs={12} sm={6} md={4} lg={4}>
				<p>{t("common.__language__")} :</p>
			</Col>
			<Col xs={12} sm={6} md={8} lg={8}>
				<LanguageSwitcher userLocale={language} languages={languages} setLanguage={setLanguage} />
			</Col>
		</>
	);
};

export default HandleLanguage;
