import React from "react";
import { useParams } from "react-router-dom";
import Moodweek from "../components/Moodweek/Moodweek";
import FilteredTimelineContainer from "../components/Timeline/FilteredTimelineContainer";
import UserStateInterface from "../interfaces/UserStateInterface";
import AccessControl from "../Layout/AccessControl";
import ContentLayout from "../Layout/ContentLayout";

interface TimelineProps {
	userState: UserStateInterface;
	userDispatch: Function;
}

export default function Timeline({ userState, userDispatch }: TimelineProps) {
	const { filter } = useParams<{ filter?: string }>();

	return (
		<AccessControl userState={userState}>
			<ContentLayout userState={userState} userDispatch={userDispatch}>
				<Moodweek seniorSelectedId={userState.seniorSelectedId} />
				<FilteredTimelineContainer userState={userState} filter={filter} />
			</ContentLayout>
		</AccessControl>
	);
}
