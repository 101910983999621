import React, { useState, useContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import { FamilyUserContext } from "../contexts/FamilyUserContext";
import MessageBox from "../components/MailBox/MessageBox";
import Documents from "../components/MailBox/Documents";
import SeniorDocuments from "../components/MailBox/SeniorDocuments";
import VisioRequest from "../components/MailBox/VisioRequest";
import MailboxNav from "../components/MailBox/MailboxNav";
import MailBox from "../components/MailBox/MailBox";
import MailBoxMessageDetails from "../components/MailBox/MailBoxMessageDetails";
import MailBoxReducer from "../components/MailBox/MailBoxReducer";
import UserStateInterface from "../interfaces/UserStateInterface";
import ContentLayout from "../Layout/ContentLayout";

interface MessagesProps {
	userDispatch: Function;
	userState: UserStateInterface;
}

export default function Messages({ userState, userDispatch }: MessagesProps) {
	const { component } = useParams<{ component: string }>();
	const { id } = useParams<{ id?: string }>();
	const { mode } = useParams<{ mode?: string }>();
	const [openResponseForm, setOpenResponseForm] = useState(mode === "answer" ? true : false);
	const { seniorHome } = useContext(FamilyUserContext);
	const isPrincipal = userState.familyUser.principal;
	const seniorSelectedId = userState.seniorSelectedId;
	const homeId = seniorHome.id;

	const initialState = {
		loading: false,
		messages: [],
		documents: [],
		seniorDocuments: [],
		error: false,
		success: false,
		msg: null
	};

	const [msgStore, msgDispatch] = useReducer(MailBoxReducer, initialState);

	const handleComponent = () => {
		switch (component) {
			case "1":
				if (id) {
					return (
						<MailBoxMessageDetails
							userDispatch={userDispatch}
							msgId={id}
							homeId={homeId}
							seniorSelectedId={seniorSelectedId}
							userState={userState}
							openResponseForm={openResponseForm}
							setOpenResponseForm={setOpenResponseForm}
						/>
					);
				} else {
					return <MailBox userState={userState} userDispatch={userDispatch} msgStore={msgStore} msgDispatch={msgDispatch} />;
				}
			case "2":
				return <VisioRequest userState={userState} />;
			case "3":
				return isPrincipal ? (
					<MessageBox userState={userState} />
				) : (
					<MailBox userState={userState} userDispatch={userDispatch} msgStore={msgStore} msgDispatch={msgDispatch} />
				);
			case "4":
				return (
					<Documents
						userState={userState}
						userDispatch={userDispatch}
						seniorSelectedId={seniorSelectedId}
						homeId={homeId}
						msgStore={msgStore}
						msgDispatch={msgDispatch}
					/>
				);
			case "5":
				return isPrincipal ? (
					<SeniorDocuments
						userState={userState}
						userDispatch={userDispatch}
						isPrincipal={isPrincipal}
						seniorSelectedId={seniorSelectedId}
						msgStore={msgStore}
						msgDispatch={msgDispatch}
					/>
				) : (
					<Documents
						userState={userState}
						userDispatch={userDispatch}
						seniorSelectedId={seniorSelectedId}
						homeId={homeId}
						msgStore={msgStore}
						msgDispatch={msgDispatch}
					/>
				);
			default:
				return <MailBox userState={userState} userDispatch={userDispatch} msgStore={msgStore} msgDispatch={msgDispatch} />;
		}
	};

	return (
		<ContentLayout userState={userState} userDispatch={userDispatch}>
			<>
				<MailboxNav component={component} isPrincipal={isPrincipal} userState={userState} />
				<div className="space"></div>
				{handleComponent()}
			</>
		</ContentLayout>
	);
}
