import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-grid-system";
import { useForm } from "react-hook-form";
import "./UserSettings.scss";
import ErrorContainer from "../../Layout/ErrorContainer";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import UserStateInterface from "../../interfaces/UserStateInterface";
import { BsXCircle } from "react-icons/bs";
import HandleTimezone from "./HandleTimezone";
import HandleLanguage from "./HandleLanguage";
import useLogout from "../../hooks/useLogout";
interface UpdateUserSettingsProps {
	userDispatch: Function;
	setEditMode: Function;
	userState: UserStateInterface;
}
interface InputValues {
	first_name: string;
	last_name: string;
	phone: string;
	email: string;
	locale: string;
}

const UpdateUserSettings = ({ userState, userDispatch, setEditMode }: UpdateUserSettingsProps) => {
	const { t } = useTranslation("common");
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<InputValues>();
	const [httpResponse, setHttpResponse] = useState<{ error: boolean } | null>(null);
	const familyUser = userState.familyUser;
	const familyUserTimezone = { value: familyUser.timezone, label: familyUser.timezone.split("/").join(" / ") };
	const [language, setLanguage] = useState(familyUser.locale);
	const [timezone, setTimezone] = useState<{ value: string; label: string }>(familyUserTimezone);
	const [showTimezoneChangeAlert, setShowTimezoneChangeAlert] = useState(false);

	const logout = useLogout({ userDispatch });
	interface timezoneAlert {
		showTimezoneChangeAlert: boolean;
	}

	const closeTimezoneAlert = () => {
		setShowTimezoneChangeAlert(false);
		setEditMode(false);
	};

	const TimezoneAlertModal = ({ showTimezoneChangeAlert }: timezoneAlert) => {
		return showTimezoneChangeAlert ? (
			<div className="overlay">
				<div className="modal">
					<div className="modal-close" role="button" onClick={closeTimezoneAlert}>
						<BsXCircle style={{ height: "1rem" }} />
					</div>
					<div className="modal-body">
						<h3>{t("common.__timezone__")}</h3>
						<div className="underliner"></div>
						<p className="mt-5 mb-5">{t("user.__timezoneChangeWarning__")}</p>
						<button type="button" className="dark-button" onClick={logout}>
							{t("user.__logout__")}
						</button>
					</div>
				</div>
			</div>
		) : null;
	};

	const onSubmit = (data: InputValues) => {
		const userData = {
			family_user: {
				first_name: data.first_name,
				last_name: data.last_name,
				phone: data.phone,
				email: data.email,
				locale: language,
				timezone: timezone ? timezone.value : familyUser.timezone
			}
		};
		http
			.put(`${url.BASEURL}/me`, userData)
			.then(res => {
				setHttpResponse({ error: false });
				userDispatch({
					type: "UPDATE_USER",
					payload: res.data.family_user
				});
				if (familyUserTimezone.value !== timezone.value) {
					setShowTimezoneChangeAlert(true);
				} else {
					setEditMode(false);
				}
			})
			.catch(() => {
				setHttpResponse({ error: true });
			});
	};

	return (
		<div className="light-card">
			<h2>{t("user.__updateMyAccount__")}</h2>
			<div className="underliner"></div>

			<ErrorContainer
				alert={t("user.__updateUserAccountError__")}
				success={t("user.__updateUserAccountSuccess__")}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Row align="center">
					<Col xs={12} sm={6} md={4} lg={4}>
						<p>{t("common.__firstname__")} :</p>
					</Col>
					<Col xs={12} sm={6} md={8} lg={8}>
						<input
							type="text"
							defaultValue={familyUser.first_name}
							{...register("first_name", { required: true })}
							aria-describedby="first_namelHelp"
						/>
						{errors.first_name ? (
							<p className="red">{t("common.__requiredInput__")}</p>
						) : (
							<small id="first_namelHelp" className="meta right">
								{t("common.__required__")}
							</small>
						)}
					</Col>
					<Col xs={12} sm={6} md={4} lg={4}>
						<p>{t("common.__name__")}:</p>
					</Col>
					<Col xs={12} sm={6} md={8} lg={8}>
						<input
							type="text"
							defaultValue={familyUser.last_name}
							aria-describedby="last_namelHelp"
							{...register("last_name", { required: true })}
						/>
						{errors.last_name ? (
							<p className="red">{t("common.__requiredInput__")}</p>
						) : (
							<small id="last_namelHelp" className="meta right">
								{t("common.__required__")}
							</small>
						)}
					</Col>
					<Col xs={12} sm={6} md={4} lg={4}>
						<p>{t("common.__mail__")} :</p>
					</Col>
					<Col xs={12} sm={6} md={8} lg={8}>
						<input
							type="email"
							defaultValue={familyUser.email}
							aria-describedby="emailHelp"
							{...register("email", { required: true })}
						/>
						{errors.email ? (
							<p className="red">{t("common.__requiredInput__")}</p>
						) : (
							<small id="emaillHelp" className="meta right">
								{t("common.__required__")}
							</small>
						)}
					</Col>
					<Col xs={12} sm={6} md={4} lg={4}>
						<p>{t("common.__phone__")} :</p>
					</Col>
					<Col xs={12} sm={6} md={8} lg={8}>
						<input type="text" defaultValue={familyUser.phone} {...register("phone", { required: false })} />
					</Col>
					<HandleLanguage language={language} setLanguage={setLanguage} />
					<HandleTimezone timezone={timezone} setTimezone={setTimezone} familyUserTimezone={familyUserTimezone} />
					<Col className="center">
						<br />
						<button className="light-button large" type="button" onClick={() => setEditMode(false)}>
							{t("common.__cancel__")}
						</button>
					</Col>
					<Col className="center">
						<br />
						<button type="submit" className="dark-button large">
							{t("common.__save__")}
						</button>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						<br />
					</Col>
				</Row>
			</form>
			<TimezoneAlertModal showTimezoneChangeAlert={showTimezoneChangeAlert} />
		</div>
	);
};

export default UpdateUserSettings;
