import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";
import {
	BsX,
	BsList,
	BsCalendar,
	// BsListCheck,
	BsTabletLandscape,
	BsEnvelope,
	BsFillCircleFill,
	BsPersonCircle
} from "react-icons/bs";
import useWindowSize from "../../hooks/useWindowSize";
import UserStateInterface from "../../interfaces/UserStateInterface";

const ResponsiveNav = ({ userState }: { userState: UserStateInterface }) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);
	const size = useWindowSize();

	const seniors = userState.familyUser.seniors;
	const mySenior = seniors.find(senior => senior.id === userState.seniorSelectedId);
	const notifications: number = mySenior
		? mySenior.nb_unread_message + mySenior.nb_unread_senior_document + mySenior.nb_unread_home_document
		: 0;
	const linkMessages = mySenior ? mySenior.nb_unread_senior_message : 0;
	const isE4linked = mySenior ? mySenior.e4linked : false;

	const handleLogoLink = () => {
		if (userState.isLogged) {
			history.push("/settings/myaccount");
		} else history.push("/");
	};

	return (
		<div className="header">
			{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
				<img src="../../img/Logo-eforlink.png" alt="Logo eforlink" id="logo" onClick={handleLogoLink} />
			) : (
				<img src="../../img/amd/amdlink.png" alt="Logo AMD link" id="amd-logo" onClick={handleLogoLink} />
			)}
			<div className="right-menu">
				<ul className={click ? "nav-options active" : "nav-options"}>
					{/* <li className="option" onClick={closeMobileMenu}>
						{isE4linked ? (
							<Link
								to={`/activities/`}
								className={/activities/.test(window.location.href) ? "nav-option-link-selected" : "nav-option-link"}
							>
								<BsListCheck className="nav-icon" />
								{t("common.__timeline__")}
							</Link>
						) : (
							<p style={{ color: "#b5b5b5" }}>
								<BsListCheck className="nav-icon" />
								{t("common.__timeline__")}
							</p>
						)}
					</li> */}
					<li className="option" onClick={closeMobileMenu}>
						<Link to={`/flow`} className={/flow/.test(window.location.href) ? "nav-option-link-selected" : "nav-option-link"}>
							<BsCalendar className="nav-icon" />
							{t("common.__news__")}
						</Link>
					</li>
					<li className="option" onClick={closeMobileMenu}>
						{isE4linked ? (
							<Link
								to={`/profile/1`}
								className={/profile/.test(window.location.href) ? "nav-option-link-selected" : "nav-option-link"}
							>
								{linkMessages > 0 ? (
									<span className="nav-option-notification">
										<BsTabletLandscape className="nav-icon" />
										<span className="link-notification">
											<BsFillCircleFill className="link-notification-icon red" />
											<span className="responsive-link-notification-counter">{linkMessages}</span>
										</span>
									</span>
								) : (
									<BsTabletLandscape className="nav-icon" />
								)}
								{t("common.__link__")}
							</Link>
						) : (
							<p style={{ color: "#b5b5b5" }}>
								<BsTabletLandscape className="nav-icon" />
								{t("common.__link__")}
							</p>
						)}
					</li>

					<li className="option" onClick={closeMobileMenu}>
						<Link
							to={`/messages/1`}
							className={/messages/.test(window.location.href) ? "nav-option-link-selected" : "nav-option-link"}
						>
							{notifications > 0 ? (
								<span className="nav-option-notification">
									<BsEnvelope className="nav-icon" />
									<span className="link-notification">
										<BsFillCircleFill className="link-notification-icon red" />
										<span className="responsive-link-notification-counter">{notifications}</span>
									</span>
								</span>
							) : (
								<BsEnvelope className="nav-icon" />
							)}
							{t("common.__mailbox__")}
						</Link>
					</li>
					<li className="option" onClick={closeMobileMenu}>
						<Link
							to={`/settings/myaccount`}
							className={/settings/.test(window.location.href) ? "nav-option-link-selected" : "nav-option-link"}
						>
							<BsPersonCircle className="nav-icon" />
							{t("common.__myAccount__")}
						</Link>
					</li>
				</ul>
			</div>
			{size.width > 790 ? null : (
				<div className="mobile-menu" onClick={handleClick}>
					{click ? <BsX className="menu-icon" /> : <BsList className="menu-icon" />}
				</div>
			)}
		</div>
	);
};

export default ResponsiveNav;
