import React, { useState, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsChatSquare } from "react-icons/bs";
import { Row, Col } from "react-grid-system";
import AddCommentForm from "./AddCommentForm";
import CommentsList from "./CommentsList";
import CommentsReducer from "./CommentsReducer";
import UserStateInterface from "../../../interfaces/UserStateInterface";
import "./Comments.scss";
interface CommentsProps {
	postId: number;
	commentsCounter: number;
	seniorSelectedId: number;
	userState: UserStateInterface;
}

const initialState = {
	comments: [],
	error: false,
	success: false
};

export default function Comments({ postId, commentsCounter, userState, seniorSelectedId }: CommentsProps) {
	const { t } = useTranslation("common");
	const [addComment, setAddComment] = useState(true);
	const [answerComment, setAnswerComment] = useState({ answer: false, id: null });
	const [commentStore, commentDispatch] = useReducer(CommentsReducer, initialState);
	const [counter, setCounter] = useState(commentsCounter);
	const [viewComments, setViewComments] = useState(commentsCounter > 0 ? true : false);

	useEffect(() => {
		if (commentsCounter > 0) {
			setAddComment(false);
		}
	}, [commentsCounter]);

	useEffect(() => {
		return function cleanup() {
			commentDispatch({
				type: "RESET_ES",
				payload: ""
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<Row>
			<Col xs={10} sm={10} md={10} lg={10}>
				<p
					className="social-text-link"
					style={{ marginTop: "0.75rem" }}
					onClick={() => setViewComments(prevState => !prevState)}
					data-testid="comments-counter"
				>
					<BsChatSquare style={{ marginRight: "0.25rem", verticalAlign: "middle", width: "auto", height: "21px" }} /> {counter}{" "}
					{t("news.__comments__")}
				</p>
			</Col>
			<Col xs={2} sm={2} md={2} lg={2}>
				{addComment ? null : (
					<button type="button" className="dark-button right" onClick={() => setAddComment(true)} data-testid="comment-button">
						{t("news.__addComment__")}
					</button>
				)}
			</Col>
			<Col xs={12} sm={12} md={12} lg={12}>
				{addComment ? (
					<AddCommentForm
						postId={postId}
						commentStore={commentStore}
						commentDispatch={commentDispatch}
						userState={userState}
						seniorSelectedId={seniorSelectedId}
						addComment={addComment}
						setAddComment={setAddComment}
						answerComment={answerComment}
						setAnswerComment={setAnswerComment}
						setCounter={setCounter}
					/>
				) : null}
				{viewComments ? (
					<CommentsList
						postId={postId}
						commentStore={commentStore}
						commentDispatch={commentDispatch}
						userState={userState}
						seniorSelectedId={seniorSelectedId}
						addComment={addComment}
						setAddComment={setAddComment}
						answerComment={answerComment}
						setAnswerComment={setAnswerComment}
						setCounter={setCounter}
					/>
				) : (
					<br />
				)}
			</Col>

			<Col xs={12} sm={12} md={12} lg={12}>
				<div className="space"></div>
			</Col>
		</Row>
	);
}
