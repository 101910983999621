import React from "react";
import { useTranslation } from "react-i18next";
import { Table, SelectColumnFilter } from "../../utils/Table";
import "../../utils/Table.scss";
import { BsLightning, BsFillLightningFill, BsFillPersonFill, BsFillCursorFill } from "react-icons/bs";
import * as constants from "../../Constants/MessagesConstants";
import UserStateInterface from "../../interfaces/UserStateInterface";
import { MessageInterface } from "../../interfaces/MessagesInterface";
import { formatedDateFromString } from "../../utils/Datetime";
import { displayMessages, handleIcon, handleResponse } from "../../utils/MailBoxUtils";

interface MailBoxContainerProps {
	msgToDisplay: MessageInterface[];
	userDispatch: Function;
	setShowCancelVisio: Function;
	setShowRejectVisio: Function;
	msgDispatch: Function;
	userState: UserStateInterface;
	visioIsAccepted: Function;
}

const MailBoxContainer = ({
	msgToDisplay,
	setShowCancelVisio,
	setShowRejectVisio,
	userState,
	userDispatch,
	msgDispatch,
	visioIsAccepted
}: MailBoxContainerProps) => {
	const { t } = useTranslation("common");

	const displayUser = (user: { first_name: string; last_name: string }) => {
		return user.first_name ? (
			<p className="meta">
				<BsFillPersonFill style={{ marginRight: "0.5rem", verticalAlign: "middle" }} />
				{user.first_name} {user.last_name}
			</p>
		) : null;
	};

	const displayPrincipal = (type: number, familyUser: { first_name: string; last_name: string; id: number }) => {
		if (familyUser.id !== userState.familyUser.id) {
			switch (type) {
				case constants.FAMILY_ACCEPT_VISIO:
				case constants.FAMILY_ASK_FOR_VISIO:
				case constants.FAMILY_CANCEL_VISIO:
				case constants.FAMILY_REJECT_VISIO:
				case constants.MSG_FROM_FAMILY_TO_USER:
					return (
						<p className="different-sender">
							<BsFillCursorFill style={{ verticalAlign: "middle", marginRight: "0.5rem" }} />
							{t("mailbox.__msgFromPrincipal__")} : {familyUser.last_name} {familyUser.first_name}
						</p>
					);
				case constants.USER_ACCEPT_VISIO:
				case constants.USER_ASK_FOR_VISIO:
				case constants.USER_CANCEL_VISIO:
				case constants.USER_REJECT_VISIO:
				case constants.MSG_FROM_USER_TO_FAMILY:
				case constants.DUPLICATED_MSG_FROM_USER_TO_FAMILY:
				case constants.WELCOME_MESSAGE:
					return (
						<p className="different-sender">
							<BsFillCursorFill style={{ verticalAlign: "middle", marginRight: "0.5rem" }} />
							{t("mailbox.__msgForPrincipal__")} : {familyUser.last_name} {familyUser.first_name}
						</p>
					);
				default:
					return;
			}
		} else {
			return;
		}
	};

	const handleDate = (date: string, type: number, unread: boolean) => {
		if (date) {
			switch (type) {
				case constants.MSG_FROM_USER_TO_FAMILY:
				case constants.DUPLICATED_MSG_FROM_USER_TO_FAMILY:
				case constants.MULTI_MSG_FROM_USER_TO_FAMILY:
				case constants.USER_ASK_FOR_VISIO:
				case constants.USER_REJECT_VISIO:
				case constants.USER_CANCEL_VISIO:
					return unread ? (
						<>
							<BsFillLightningFill className="red" style={{ verticalAlign: "middle" }} />
							<span className="meta-red">
								<b>
									{t("mailbox.__receivedOn__")}
									{formatedDateFromString(date, "Pp", userState.locale)}
								</b>
							</span>
						</>
					) : (
						<p className="meta">
							<BsLightning style={{ marginRight: "0.5rem", verticalAlign: "middle" }} />
							{t("mailbox.__receivedOn__")} {formatedDateFromString(date, "Pp", userState.locale)}
						</p>
					);
				case constants.MSG_FROM_FAMILY_TO_USER:
				case constants.FAMILY_ASK_FOR_VISIO:
				case constants.FAMILY_CANCEL_VISIO:
				case constants.FAMILY_REJECT_VISIO:
					return unread ? (
						<>
							<BsFillLightningFill className="red" style={{ verticalAlign: "middle" }} />
							<span className="meta-red">
								<b>
									{t("mailbox.__sentOn__")}
									{formatedDateFromString(date, "Pp", userState.locale)}
								</b>
							</span>
						</>
					) : (
						<p className="meta">
							<BsLightning style={{ marginRight: "0.5rem", verticalAlign: "middle" }} />
							{t("mailbox.__sentOn__")} {formatedDateFromString(date, "Pp", userState.locale)}
						</p>
					);
				case constants.USER_ACCEPT_VISIO:
				case constants.FAMILY_ACCEPT_VISIO:
					return unread ? (
						<>
							<BsFillLightningFill className="red" style={{ verticalAlign: "middle" }} />
							<span className="meta-red">
								<b>{formatedDateFromString(date, "Pp", userState.locale)}</b>
							</span>
						</>
					) : (
						<p className="meta">
							<BsLightning style={{ marginRight: "0.5rem", verticalAlign: "middle" }} />
							{formatedDateFromString(date, "Pp", userState.locale)}
						</p>
					);

				default:
					return <p className="meta">{formatedDateFromString(date, "Pp", userState.locale)}</p>;
			}
		} else {
			return;
		}
	};

	const columns = [
		{
			Header: t("common.__type__"),
			accessor: "typeName",
			Filter: SelectColumnFilter,
			maxWidth: 50,
			minWidth: 30,
			width: 40,
			Cell: (row: any) => (
				<div className="center">
					{handleIcon(
						row.row.original.type,
						row.row.original.unread,
						row.row.original.subject,
						false,
						row.row.original.family_user,
						userState.familyUser.id
					)}
				</div>
			)
		},
		{
			Header: t("link.__msgObject__"),
			accessor: "subject",
			minWidth: 200,
			width: 350,
			Cell: (row: any) => (
				<>
					{displayPrincipal(row.row.original.type, row.row.original.family_user)}
					{handleDate(row.row.original.updated_at, row.row.original.type, row.row.original.unread)}
					{displayUser(row.row.original.user)}
					<div className={row.row.original.unread ? "unread mt-2" : "mt-2"}>
						{displayMessages(row.row.original, userState.locale)}
					</div>
				</>
			)
		},
		{
			Header: "",
			accessor: "unread",
			disableFilters: true,
			disableSortBy: true,
			Cell: (row: any) => (
				<div className="right">
					{handleResponse(
						row.row.original.type,
						row.row.original.id,
						row.row.original.unread,
						row.row.original.family_user.id,
						row.row.original.visio_datetime,
						userState.familyUser.id,
						row.row.original.senior.id,
						setShowRejectVisio,
						userDispatch,
						false,
						msgDispatch,
						visioIsAccepted,
						setShowCancelVisio
					)}
				</div>
			)
		}
	];

	return msgToDisplay.length === 0 ? (
		<p>{t("mailbox.__noMessage__")}</p>
	) : (
		<Table columns={columns} data={msgToDisplay} displayPaginationSelection={true} />
	);
};

export default MailBoxContainer;
