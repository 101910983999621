import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as url from "../../Constants/Url";
import http from "../../Services/Interceptor";
import CancelVisio from "./CancelVisio";
import RejectVisio from "./RejectVisio";
import MailBoxContainer from "./MailBoxContainer";
import * as constants from "../../Constants/MessagesConstants";
import UserStateInterface from "../../interfaces/UserStateInterface";
import ModalContainer from "../../Layout/ModalContainer";
import { BsX } from "react-icons/bs";
import HeaderContainer from "../../Layout/HeaderContainer";

interface MailBoxProps {
	userDispatch: Function;
	msgStore: any;
	msgDispatch: Function;
	userState: UserStateInterface;
}

const MailBox = ({ userState, userDispatch, msgStore, msgDispatch }: MailBoxProps) => {
	const { t } = useTranslation("common");
	const isPrincipal = userState.familyUser.principal;
	const [showCancelVisio, setShowCancelVisio] = useState({ show: false, info: null });
	const [showRejectVisio, setShowRejectVisio] = useState({ show: false, info: null });
	const [error, setError] = useState(false);
	const seniorSelectedId = userState.seniorSelectedId;

	useEffect(() => {
		return function cleanup() {
			msgDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (seniorSelectedId) {
			http.get(`${url.BASEURL}/seniors/${seniorSelectedId}/all/unread/count`).then(res => {
				userDispatch({
					type: "UPDATE_COUNTERS",
					payload: { senior: seniorSelectedId, counters: res.data }
				});
			});
		}
	}, [seniorSelectedId]);

	useEffect(() => {
		if (seniorSelectedId) {
			msgDispatch({
				type: "LOADING"
			});

			http
				.get(`${url.SENIORS}/${seniorSelectedId}/homes/messages/titles`)
				.then(res => {
					const allMessages = res.data.family_user_messages;
					const messages = allMessages.map(function (message: any) {
						return {
							...message,
							typeName: displayTypeName(message.type)
						};
					});
					msgDispatch({
						type: "GET_MESSAGES",
						payload: messages
					});
				})
				.catch(() => {
					msgDispatch({
						type: "GET_MESSAGES",
						payload: []
					});
				});
		}
		// eslint-disable-next-line
	}, [seniorSelectedId]);

	const visioIsAccepted = (id: number) => {
		if (id) {
			setError(false);
			const data = {
				message_id: id
			};
			http
				.post(`${url.HOMES}/visio/accept`, data)
				.then(() => {
					userDispatch({
						type: "READ_MESSAGE",
						payload: { senior: seniorSelectedId, nb: 1 }
					});
					msgDispatch({
						type: "FAMILY_ACCEPT_VISIO",
						payload: id
					});
				})
				.catch(() => setError(true));
		}
	};

	const displayTypeName = (type: number) => {
		switch (type) {
			case constants.USER_ASK_FOR_VISIO:
			case constants.FAMILY_ASK_FOR_VISIO:
				return t("mailbox.__askForVisio__");
			case constants.USER_ACCEPT_VISIO:
			case constants.FAMILY_ACCEPT_VISIO:
				return t("mailbox.__visioAppointment__");
			case constants.USER_REJECT_VISIO:
			case constants.USER_CANCEL_VISIO:
			case constants.FAMILY_CANCEL_VISIO:
			case constants.FAMILY_REJECT_VISIO:
				return t("mailbox.__visioAppointmentCanceled__");
			case constants.MSG_FROM_FAMILY_TO_USER:
			case constants.MSG_FROM_USER_TO_FAMILY:
			case constants.WELCOME_MESSAGE:
			case constants.DUPLICATED_MSG_FROM_USER_TO_FAMILY:
			case constants.MULTI_MSG_FROM_USER_TO_FAMILY:
				return t("common.__message__");
			default:
				return t("common.__message__");
		}
	};

	const button = () => {
		return isPrincipal ? (
			<Link to="/messages/3">
				<button type="button" className="light-button" style={{ marginTop: "15px" }}>
					{t("link.__newMsg__")}
				</button>
			</Link>
		) : null;
	};

	return (
		<>
			<HeaderContainer title={t("common.__mailbox__")} button={button} />

			{userState.familyUser.nb_unread_message > 0 ? (
				<b>{t("mailbox.__mailboxInfo__")}</b>
			) : (
				<>
					<p>
						<b>{t("mailbox.__mailboxInfo__")}</b>
					</p>
					<p className="meta">{t("mailbox.__noNewMsg__")}</p>
				</>
			)}
			<p className="meta">
				{t("mailbox.__mailboxInfo2__")}
				<Link to="/profile/1">
					<b>Link</b>
				</Link>
			</p>
			<br />
			{msgStore.loading ? (
				<p>
					<b>{t("common.__loading__")}...</b>
				</p>
			) : (
				<>
					{error ? (
						<p className="alert">
							{t("common.__errorOccured__")}
							<span className="right fake-link" role="button" onClick={() => setError(false)}>
								<BsX />
							</span>
						</p>
					) : null}
					<MailBoxContainer
						msgToDisplay={msgStore.messages}
						setShowCancelVisio={setShowCancelVisio}
						setShowRejectVisio={setShowRejectVisio}
						userState={userState}
						userDispatch={userDispatch}
						msgDispatch={msgDispatch}
						visioIsAccepted={visioIsAccepted}
					/>
				</>
			)}

			<ModalContainer show={showCancelVisio} setShow={setShowCancelVisio}>
				<CancelVisio
					show={showCancelVisio}
					setShow={setShowCancelVisio}
					userDispatch={userDispatch}
					seniorSelectedId={seniorSelectedId}
					userState={userState}
				/>
			</ModalContainer>
			<ModalContainer show={showRejectVisio} setShow={setShowRejectVisio}>
				<RejectVisio
					show={showRejectVisio}
					setShow={setShowRejectVisio}
					userState={userState}
					userDispatch={userDispatch}
					seniorSelectedId={seniorSelectedId}
					msgDispatch={msgDispatch}
				/>
			</ModalContainer>
		</>
	);
};

export default MailBox;
