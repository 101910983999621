import React from "react";
import { Redirect } from "react-router-dom";
import UserStateInterface from "../interfaces/UserStateInterface";

interface AccessControlProps {
	children: React.ReactNode;
	userState: UserStateInterface;
}

const AccessControl = ({ userState, children }: AccessControlProps) => {
	const mySeniors = userState.familyUser.seniors;
	const seniorSelectedId = userState.seniorSelectedId;
	const mySenior = mySeniors.find(senior => senior.id === seniorSelectedId);
	const accessAllowed = mySenior ? mySenior.e4linked : false;
	return accessAllowed ? <>{children}</> : <Redirect to="/social" />;
};
export default AccessControl;
