import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationDetails from "./NotificationDetails";
import ResponseForm from "./ResponseForm";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import UserStateInterface from "../../interfaces/UserStateInterface";
import { MessageInterface } from "../../interfaces/MessagesInterface";
import HeaderContainer from "../../Layout/HeaderContainer";

interface MailBoxMessageDetailsProps {
	userDispatch: Function;
	homeId: number;
	msgId: string;
	seniorSelectedId: number;
	openResponseForm: boolean;
	setOpenResponseForm: Function;
	userState: UserStateInterface;
}

export default function MailBoxMessageDetails({
	userState,
	userDispatch,
	msgId,
	seniorSelectedId,
	openResponseForm,
	setOpenResponseForm
}: MailBoxMessageDetailsProps) {
	const { t } = useTranslation("common");
	const [thread, setThread] = useState<MessageInterface[]>([]);
	const [error, setError] = useState(false);
	const [subject, setSubject] = useState(" ");
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		if (msgId) {
			http
				.get(`${url.BASEURL}${url.THREAD}/${msgId}/thread`)
				.then(res => {
					setError(false);
					const response: MessageInterface[] = res.data.family_user_messages;
					setThread(response);
					const threadSize = response.length;
					const firstMessage = response[threadSize - 1];
					setSubject(firstMessage.subject);
					const unreadMsg = response.filter(msg => msg.unread);
					const nbUnreadMsg = unreadMsg.length;
					if (nbUnreadMsg > 0) {
						userDispatch({
							type: "READ_MESSAGE",
							payload: { senior: seniorSelectedId, nb: nbUnreadMsg }
						});
					}
				})
				.catch(() => setError(true));
		}
		// eslint-disable-next-line
	}, [update]);

	return (
		<>
			<HeaderContainer title={t("common.__mailbox__")} />
			{openResponseForm ? (
				<ResponseForm
					msgId={msgId}
					openResponseForm={openResponseForm}
					setOpenResponseForm={setOpenResponseForm}
					setUpdate={setUpdate}
					subject={subject}
				/>
			) : null}
			<NotificationDetails
				userState={userState}
				openResponseForm={openResponseForm}
				setOpenResponseForm={setOpenResponseForm}
				subject={subject}
				error={error}
				thread={thread}
			/>
		</>
	);
}
