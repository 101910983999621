import React, { useEffect, useState } from "react";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import UserStateInterface from "../../interfaces/UserStateInterface";
import VisioButtonContainer from "./VisioButtonContainer";

interface VisioButtonProps {
	userState: UserStateInterface;
}
interface NextVisioInterface {
	visio_datetime: string;
	room: string | null;
	subject: string | null;
	token: string | null;
	state: string;
}

const VisioButton = ({ userState }: VisioButtonProps) => {
	const [nextVisio, setNextVisio] = useState<NextVisioInterface | null>(null);
	const seniorId: number = userState.seniorSelectedId;

	const handleVisio = (seniorId: number) => {
		http
			.get(`${url.BASEURL}/visio/next/scheduled/with/${seniorId}`)
			.then(res => setNextVisio(res.data))
			.catch(() => setNextVisio(null));
	};

	useEffect(() => {
		if (seniorId) {
			handleVisio(seniorId);
		}
	}, [seniorId]);

	return nextVisio ? (
		<VisioButtonContainer seniorId={seniorId} userLocale={userState.locale} nextVisio={nextVisio} handleVisio={handleVisio} />
	) : null;
};

export default VisioButton;
